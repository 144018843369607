<template>
  <div class="mobile-home">
    <!-- 头部开始 -->
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_news_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <!-- 头部结束 -->
    <!-- 中间内容开始 -->
    <div class="mo-main-contain" v-show="$route.query.id == 6">
      <!-- 新闻三 -->
      <div class="news-title-box">
        <div class="news-title">传世商业价值研究院暨医药产业互联论坛</div>
        <div class="news-date">发布时间：2021-07-20 15:26</div>
      </div>
      <div class="news-content-box">
        <img class="mt0 mb0" src="../../../assets/images/news_block1_01.png" alt="" />
        <p>2021年3月10日，传世商业价值研究院启动暨医药产业互联论坛在西安交通大学曲江校区成功召开！</p>
        <p>
          会议由传世般若和凯迈咨询&凯迈投资联合主办，双方共同设立传世商业价值研究院，拟通过医药政策、行业、企业研究及互联技术发展研究，找准医药产业互联发展趋势，协同传世般若为行业提供技术加持、数字赋能、产业协同、智慧升级的企业竞争力解决方案。
        </p>
        <img src="../../../assets/images/news_block1_02.png" alt="" />
        <p>
          本次会议陕西省医药行业大佬云集，原延安必康总裁、现中国医药物资协会副会长、凯迈合伙人香兴福，原陕西医药控股集团党委常委祁万江，润沣资本董事长丰雷，四医大军事医学装备与计量学教研室主任罗二平，步长制药集团董事薛人珲，陕西康惠制药总经理陈亚龙，天远集团董事长杨志宏，陕药集团中药投资公司副总经理齐福永，重药控股陕西医药有限公司党支部书记、总经理耿直，陕西恒伦优德董事长王艳博士，空军军医大学口腔医院正畸科副主任医师、副教授武俊杰等领导和嘉宾及医院代表出席了会议，共襄盛举，将传世研究院打造成医药产业互联解决方案研究与创新的新高地。
        </p>
        <p>
          产业论坛由香兴福先生主持，与会嘉宾围绕如何让医药企业跳出传统守旧、竞争乏力、增长无继的泥潭？如何让医药企业利用产业互联和数字竞争力的思维模式实现转型升级、竞争力跨越等话题展开热烈讨论。其中，凯迈咨询董事长彭恩泽博士分享了以“产业互联势不可挡”为主题的专题报告；传世般若总经理蘧勇向与会嘉宾分享了传世面向医药产业的理解及解决方案。
        </p>
        <img src="../../../assets/images/news_block1_03.png" alt="" />
        <p>
          最后，香兴福先生宣布传世商业价值研究院正式启动，并接受传世和凯迈委托，出任研究院第一任院长。与会嘉宾纷纷对研究院的正式成立表示热烈的祝贺，并提出了殷切的期望，希望传世商业价值研究院能够帮中国医药产业插上互联网的翅膀，引领医药产业互联。
        </p>
        <img src="../../../assets/images/news_block1_04.png" alt="" />
        <p>
          传世般若创立于2015年8月，是医药产业互联领域的国家级高新技术企业，国家健康医疗大数据工程供应链平台首选单位，是行业首个涉药运输服务平台唯一运营单位。企业为医疗机构提供智能硬件、采购执行系统和院内物流服务，为药企提供终端连接、数据采集、智慧营销解决方案。传世不断迭代创新商业模式，致力为医院提质，助药企增效，构健康生态，成为医药产业互联领域的领军企业。
        </p>
      </div>
      <div class="news-btn-box">
        <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
        <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
      </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 5">
      <!-- 新闻二 -->
      <div class="news-title-box">
        <div class="news-title">传世般若推进数字化转型：人民同泰哈药物流的选择</div>
        <div class="news-date">发布时间：2021-08-19 10:30</div>
      </div>
      <div class="news-content-box">
        <img src="../../../assets/images/news_block2.png" alt="" />
        <p>近日，人民同泰哈药物流TMS项目正式启动，哈药集团首席信息官韩总、哈药物流高总、郭总、以及传世科技张总率领双方项目组成员参加启动会。式启动，哈药集团首席信息官韩总、哈药物流高总、郭总、以及传世科技张总率领双方项目组成员参加启动会。</p>
        <p>人民同泰哈药物流TMS项目是哈药人民同泰从企业战略、供应链服务延伸和信息化的数字化转型的重要项目，也是哈药集团数字化转型的重要项目。传世科技为哈药物流提供基于云计算、数字化的SaaS供应链云平台-药链云，助力哈药人民同泰的数字化转型战略的实现。</p>
        <h1>哈药物流介绍</h1>
        <p>哈药集团哈尔滨医药商业有限公司（简称哈药物流配送中心）隶属于哈药集团人民同泰医药股份有限公司，是人民同泰股份的中央物流中心，是哈药集团“十一五”规划重点建设项目，是黑龙江省首家、东北地区规模最大的现代化医药物流配送中心，坐落于哈尔滨市呼兰利民开发区，占地面积15万平方米，于2011年1月1日正式运营。物流园区规划分为四期，目前已投资2.16亿元完成了一期、二期的建设。现仓储面积4.2万平方米，存储能力40万箱，日吞吐能力4万箱。
        </p>
        <h1>项目应用介绍</h1>
        <p>人民同泰配送线路多、网点多、车次多，送货地覆盖黑龙江全省及邻近的吉林、辽宁、内蒙等省部分地区，城配自配为主，远程配送委托三方。
        </p>
        <p>药链云使用后，将为哈药物流配送部门带来以下变化：<br>1、提供配送线路优化方法，辅助调度人员提高调度与配载效率；<br>2、配送调度功能，同时支持自配车队、第三方物流的物流派车分配；<br>3、通过与GPS、冷链配送温控设备等对接，让质量部门、司机、配送管理岗、货主、收货客户实时掌握配送动态；<br>4、司机通过移动端的操作，在配送完成时，及时回传电子回单、交货异常信息及照片；<br>5、通过与第三方物流公司的对接，及时获得配送过程的节点信息；<br>6、配送部门与销售部门实现信息互通，销售人员在ERP中就能看到配送信息，准确掌握货物实情；<br>7、为财务提供物流费用的统计及核算线上化。</p>
        <h1>供应链云平台 - 药链云介绍</h1>
        <h2>产品特点</h2>
        <p>1、支持订单管理、物流调度管理、物流资源管理；</p>
        <p>2、支持多级多仓、三方物流、院外物流、冷链物流；</p>
        <p>3、支持集散中心物流调度与管控；</p>
        <p>4、支持配送人工调度与智能调度（基于GIS的路径优化算法+配载智能算法）两种模式；</p>
        <p>5、六点成网，同时为公司客户及货主、仓储方、承运商、司机、终端客户分别提供应用端，让信息实现互联互通。</p>
        <h2>产品优势</h2>
        <p>1、医院物流端到端：药链云具备无缝链接医院物流体系的能力，贯通医院物流与企业物流，实现院内、外物流一体化管理；</p>
        <p>2、企业物流端到端：药链云具备成为企业供应链核心平台的能力，实现营销体系、仓储体系、配送体系、财务体系在订单+物流+票据的整体协同；</p>
        <p>3、三方物流端到端：对接传世云物流体系，获取物流资源，帮助企业提高三方物流管控能力、三方物流业务拓展能力、客户交付能力；</p>
        <p>4、开放式接口平台：药链云搭配开放式接口平台及即插即用型接口插件，无缝对接业务系统（ERP\WMS\OMS）、物流设备（GPS\冷链设备\物联网设备）、物流公司（佳吉、嘉里大通、中邮、顺丰、京东、中通、宅急送、华欣、城市应急等全国型物流公司）。</p>
        <h1>数字传世</h1>
        <p>致力于以供应链解决方案构筑医药产业互联，为医药企业赋能供应链优化、供应链金融、以及，供应链数字营销。</p>
      </div>
      <div class="news-btn-box">
        <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
        <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
      </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 4">
      <!-- 新闻一 -->
      <div class="news-title-box mb30">
        <div class="news-title">涉药运输平台西北区域数字供应链合作伙伴签约仪式在西安举行</div>
        <div class="news-date">发布时间：2021-10-12 12:12</div>
      </div>
      <div class="news-content-box">
        <p>随着药械“两票制”接近尾声，医药行业4+7带量采购全面推行，药械生产厂家和经营企业对产品库存前置、产品配送的要求越来越高，整个供应链链条越来越短，合规、效率、成本是各厂家及行业相关方重点关注的问题。</p>
        <p>10月25日，深圳传世般若科技有限公司（以下简称“传世”）作为涉药运输平台规则的倡议者与参与制定者参加了会议，携手京东物流、陕西天士力医药物流有限公司、陕西医药控股集团派昂医药有限责任公司共同签署了战略合作协议，共同推进涉药运输平台西北区域数字供应链和区配一体化工作，打造以陕西医药物流市场为核心区域涉药物流管控体系。</p>
        <p>依托区域数据供应链平台及区域仓配中心，为企业提供库存前置和物流可视化解决方案；根据涉药运输管理标准，建立区域内的医药物流质量管控体系，对医药运输车辆进行有效管理，提高服务质量和配送效率，为西北区域内的医药工业企业、医药商业企业、医院以及医药零售终端，提供综合性区域供应链服务。</p>
        <p>从2018年9月开始，传世已与陕西天士力医药物流有限公司在西北地区进行了合作，双方将在原有合作的基础上继续推动相关业务深入合作和共同发展。</p>
        <img src="../../../assets/images/news_block3_01.jpg" alt="" />
        <img src="../../../assets/images/news_block3_02.jpg" alt="" />
        <p>涉药运输服务平台是中国医药商业协会社会物流分会、中国交通运输协会快运分会、传世联合为行业推出的一大核心管理平台，在医药流通领域打造第一个集成社会化资源的供应链云基础设施。目前平台298家签约物流公司覆盖全国、4.4万辆合规车辆集约调度，已经有105座签约医药仓，总面积135.3万平米，医药三方仓面积达101万平米，累计发货超500万件，总订单量10万+，累计运营总货值80亿+。</p>
        <p>随着带量采购的深入开展，传世认为，未来医药行业的发展主旋律将集中在保供药品和器械的交付，而全链路交付效率和成本的优化关键就在于供应链运营和供应链技术。传世未来会再接再厉，为医药行业推出更多的供应链产品与服务。</p>
      </div>
      <div class="news-btn-box">
        <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
        <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
      </div>
    </div>
    
    <div class="mo-main-contain" v-show="$route.query.id == 3">
      <!-- 新闻五 -->
      <div class="news-title-box mb30">
        <div class="news-title">恋链平台终端服务人员招募啦！</div>
        <div class="news-date">发布时间：2021-12-25 18:32</div>
      </div>
      <div class="news-content-box">
       
        <img  src="../../../assets/mobile_images/mo_news_advert.png" alt="" />
      </div>
      <div class="news-btn-box">
        <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
        <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
      </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 2">
      <!-- 新闻四 -->
      <div class="news-title-box mb30">
        <div class="news-title">传世般若，连续入围未来医疗百强榜</div>
        <div class="news-date">发布时间：2022-06-16 22:36</div>
      </div>
      <div class="news-content-box">
        <img  src="../../../assets/mobile_images/news_block4_logo.png" alt="" />
        <p>由动脉网、VB100等共同推出的中国未来医疗百强评选活动，昨日发布中国创新数字医疗榜单，传世般若再次入围动脉网VB-100未来医疗百强榜。</p>
        <p>动脉网的未来百强榜，通过考察企业在人力资源、知识资源、重要合作伙伴资源、市场表现四大一级指标，共17项二级指标数据上的成长性与年度表现做出打分，是目前生命健康领域最受产业界、资本界关注的年度评选。</p>
        <p>（未来医疗百强榜:创立于2015年的未来医疗100强榜是由VB100、动脉网、蛋壳研究院推出的国内第一个针对非上市企业的创新医疗领域榜单，旨在遴选真正代表未来医疗的中国创新医疗者，发现我国未来医疗产业的核心力量，推动健康医疗产业的创新变革进程。）</p>
        <!-- <MobileImg
        :url="rankImgUrl"
        boxHeight="100%"
        backColor="#fff"
        marginBox="0"
        paddingBox="0"
        border="none"
      ></MobileImg> -->
        <img  src="../../../assets/mobile_images/news_block4_01.png" alt="" />
        <p>由深圳传世般若科技有限公司创立于2015年8月，国家高新技术企业，专注于以供应链解决方案，构筑医药产业互联网，向医药企业及医院提供营销及供应链数字化的综合服务。现已拥有医药供应链领域软件著作权40余项，各项专利20余项，为不同客户类型提供个性化产品服务，形成了完整的数字化供应链服务解决方案和产品，推动医药流通的整体数字化转型。</p>
        <img  src="../../../assets/mobile_images/news_block4_02.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block4_03.png" alt="" />
      </div>
      <div class="news-btn-box">
        <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
        <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
      </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 1">
      <!-- 新闻五 -->
      <div class="news-title-box mb30">
        <div class="news-title">庆祝传世般若成立7周年！</div>
        <div class="news-date">发布时间：2022-08-08 19:35</div>
      </div>
      <div class="news-content-box">
        <p>风雨同舟，携手共进！</p>
        <p>传世般若致力于成为医药行业数字化解决方案服务领军企业。</p>
        <img  src="../../../assets/mobile_images/news_block5_01.png" alt="" />
      </div>
      <div class="news-btn-box">
        <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
        <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
      </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 0">
        <!-- 新闻六 -->
        <div class="news-title-box mb30">
          <div class="news-title">传世般若数字化解决方案灯塔项目：京东方健康集中采购管理系统项目</div>
          <div class="news-date">发布时间：2023-02-22 11:43</div>
        </div>
        <div class="news-content-box">
          <img  src="../../../assets/images/news_block6_top.gif" alt="" />
          <p>
            2023年，新年立春之际，京东方健康集中采购管理系统项目正式启动，京东方健康、深圳传世般若科技有限公司的双方领导及项目组成员共同出席参加启动会。
          </p>
          <img  src="../../../assets/mobile_images/news_block6_01.png" alt="" />
          <p>
            京东方健康事业集中采购管理系统项目的项目目标是：实现数据、业务、流程的标准化，打造数字医院集团化的管理体系，最终实现医院采购的全面集团化标准管理。
          </p>

          <p>
            启动会现场，首先由项目经理向双方领导及项目组汇报了项目内容及项目推进安排，其后双方领导对项目开展进行了深入沟通。
          </p>

          <p>京东方健康事业COO章总表示：</p>

          <p>数据可视化供应链项目是今年供应链部门最重要的信息化项目，是最具数字可视化的集中管理。</p>

          <p>1、希望项目组通过本项目能够在标准化、规范化、可视化等方面结合医疗场景特点，做好调研与蓝图设计；</p>

          <p>
            2、相信传世科技在社会办医医疗集团有很好的经验，希望传世科技结合业内经验，把业务实践放在项目里做探讨，帮助实现业务共识。
          </p>

          <p>传世科技杨董事长表示：</p>

          <p>
            对京东方给予传世科技工作开展的支持表示感谢，并相信在项目推进的过程中，传世科技的产品会令京东方满意，对完成项目目标充满信心。他强调，信息化只是初步的阶段，未来还有更多的业务合作机会和空间。
          </p>
          <img  src="../../../assets/mobile_images/news_block6_02.png" alt="" />
          <p>
            健康事业是京东方面向未来，从人类健康和幸福出发所做出的战略选择，也是集团重点发展的高潜事业。京东方坚持以人为中心，医工融合创新，聚焦家庭、社区、医院场景，通过健康物联网平台将检测设备、医护与客户链接起来，形成智慧健康管理生态体系。构建以健康管理为核心、医工产品为牵引、数字医院和康养社区为支撑全周期服务闭环。
          </p>

          <p>
            京东方健康一直致力于通过模式创新，以数字化管理、数字化服务，提升运营效率，支撑集团提供更高质量的健康服务体系。随着京东方健康事业的发展，数字医院集采业务的品种范围不断扩大，集采覆盖率不断提升。
          </p>

          <p>
            目前京东方旗下运营多家医院，其中包含合肥京东方医院、北京明德医院、成都京东方医院、苏州京东方医院已经在运营。
          </p>
          <img src="../../../assets/mobile_images/news_block6_03.png" alt="" />
          <img src="../../../assets/mobile_images/news_block6_04.png" alt="" />
          <img src="../../../assets/mobile_images/news_block6_05.png" alt="" />
          <p>
            传世般若是以智能硬件为基础，结合供应链SaaS、大数据及算法的人工智能物联网（AIoT），为药企和医院提供流通领域的数字化解决方案。通过人工智能物联网连接和调度医药流通资源的众包平台，为药企和医院提供精准营销和物流优化服务。
          </p>
          <img src="../../../assets/mobile_images/news_block6_06.png" alt="" />
          <img src="../../../assets/mobile_images/news_block6_07.png" alt="" />
        </div>
        <div class="news-btn-box">
          <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
          <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
        </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 7">
      <!-- 新闻七 -->
      <div class="news-title-box mb30">
        <div class="news-title">2023中国数字医疗大会暨中国医药教育协会数字医疗专业委员会成立大会，传世科技协办，欢迎参会！</div>
        <div class="news-date">发布时间：2023-07-05 18:12</div>
      </div>
      <div class="news-content-box">
        <img  src="../../../assets/images/news_block6_top.gif" alt="" />
        <p>
          2023首届中国数字医疗大会暨中国医药教育协会数字医疗专业委员会成立大会定于7月28日-29日在北京召开。本次会议由中国医药教育协会（CMEA）主办，中国医药教育协会数字医疗专业委员会、解放军总医院第一医学中心、清华大学附属北京清华长庚医院、北京陈菊梅公益基金会、清华大学出版社、中国信息通信研究院云计算与大数据研究所共同承办。
        </p>
        <p>
          本次大会主题为“新范式 新场景 新势能”，多位院士、相关学科领军专家学者，将从政策、临床、技术和成果转化等层面，研讨以人工智能为代表的关键数字技术在医疗领域的前沿发展，解析最新临床创新范式、政策监管走向和成果转化路径。期间还将召开中国医药教育协会数字医疗专业委员会成立大会、专委会第一次全体委员会议，选举专委会第一届委员会主任委员、副主任委员、常务委员等，并审议通过专委会工作计划和管理办法。
        </p>
        <p>
          传世科技作为副秘书长单位，和中国医药教育协会数字医疗专业委员会合作，加速数字医疗业务的战略发展。基于本次合作，专委会将联合传世科技共同建设并在本次数字医疗专业委员会成立大会现场发布北京市数字医疗创新研究院和公共技术服务平台，该平台将构建基于创新协作的科技攻关与临床转化一体化平台，基于传世已经积累的软件+硬件+数据服务的数字化能力，尤其是线下行为线上化能力，为数字医疗项目提供技术服务。
        </p>
        <p>
          传世科技的数字医疗技术服务，将以积累多年的国内领先的数字化能力，为行业客户，尤其工业企业以及医院的学术带头人提供数据服务和数字医疗产品开发等服务。本次大会，传世科技作为协办单位，诚邀传世科技的合作伙伴莅临本次大会。
        </p>
        <p style="margin-top: 20px; background-color:rgb(255, 192, 0) ;text-align: center;text-indent:0">
          以下是本次大会详细信息
        </p>
        <img  src="../../../assets/mobile_images/news_block7_01.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_02.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_03.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_04.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_05.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_06.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_07.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_08.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_09.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_10.png" alt="" />
        <p style=" text-align: center;font-size:20px">
          市 场 部 出 品 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <img  src="../../../assets/mobile_images/news_block7_end.png" alt="" />
        <p>
          深圳传世般若科技有限公司（以下简称“传世科技”）成立于2015年，国家高新技术企业，国内领先的医药流通数字化解决方案服务商。
        </p>

        <p>
          传世科技目前在深圳、北京、武汉、威海、成都、天津、徐州均成立子公司，为公司集团化发展奠定了坚实的基础。传世科技以智能硬件为基础，结合供应链SaaS、大数据及算法的人工智能物联网（AIoT），为药企和医院提供流通领域的数字化解决方案；通过人工智能物联网连接和调度医药流通资源为药企和医院提供数字化营销与供应链优化服务。
        </p>
        <img  src="../../../assets/mobile_images/news_block7_list.png" alt="" />
        <img  src="../../../assets/mobile_images/news_block7_eq.png" alt="" />
       
        <!-- 
        <img style="width:50%;margin:20px 25%;" src="../../assets/mobile_images/news_block6_06.png" alt="" />
        <img style="width:50%;margin:20px 25%;" src="../../assets/mobile_images/news_block6_07.png" alt="" /> -->
      </div>
      <div class="news-btn-box">
        <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
        <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
      </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 8">
    <!-- 新闻八 -->
    <div class="news-title-box mb30">
      <div class="news-title">2023年全球数字经济大会召开，传世科技获得北京国际大数据交易所首批数据资产登记凭证</div>
      <div class="news-date">发布时间：2023-07-06 13:11</div>
    </div>
    <div class="news-content-box">
      <img  src="../../../assets/images/news_block6_top.gif" alt="" />
      <p>
        7月4日晚，北京国家会议中心灯光璀璨，受人瞩目的2023全球数字经济大会隆重开幕。中共中央政治局委员、北京市委书记尹力，中央网信办、国家网信办主任庄荣文，国家发展和改革委员会副主任丛亮，工业和信息化部副部长王江平，中国科协专职副主席、书记处书记束为，老挝万象市市长阿沙庞通·西潘敦，阿联酋阿布扎比经济发展局副局长拉希德·布洛希，新加坡通讯及新闻部部长杨莉明（线上）、新加坡资讯通信媒体发展局局长柳俊泓，埃及最高网络安全委员会执行局主席艾哈迈德·哈菲兹，联合国教科文组织助理总干事斯蒂芬妮娅·贾尼尼，美中关系全国委员会会长史蒂芬·欧伦斯等中外领导和嘉宾出席开幕式。北京市委副书记、市长殷勇主持开幕式。
      </p>
      <img  src="../../../assets/mobile_images/news_block8_01.png" alt="" />
      <p>
        探路要素创新，首发前沿成果，7月5日，2023全球数字经济大会（GDEC）“全球数据要素高峰论坛”，在国家会议中心隆重举办。作为2023全球数字经济大会的六大重磅活动之一，此次高峰论坛由全球数字经济大会组委会主办，北京市经济和信息化局、北京金融控股集团、北京国际大数据交易所等承办，涵盖四大板块，发布十项成果，云集多领域权威，全息呈现北京市领先全国的数据要素市场建设新视野、新模式、新场景、新赛道、新生态，助力“国家数据基础制度”落实落地、见行见效。加快推进数据的要素化流动和价值化演进，是加速数字经济与实体经济深度融合、创新发展的必由之路。在“国家数据基础制度”的指引下，北京市沿着数据要素的价值化路径奋力探索，重磅落地一系列创新成果。
      </p>
      <img  src="../../../assets/mobile_images/news_block8_02.png" alt="" />
      <p>
        在数据要素高峰论坛上，北京市发布了《关于更好发挥数据要素作用进一步加快发展数字经济的实施意见》，文件指出，把释放数据价值作为北京减量发展条件下持续增长的新动力，以促进数据合规高效流通使用、赋能实体经济为主线，加快推进数据产权制度和收益分配机制先行先试，围绕数据开放流动、应用场景示范、核心技术保障、发展模式创新、安全监管治理等重点，充分激活数据要素潜能，健全数据要素市场体系，为建设全球数字经济标杆城市奠定坚实基础。《实施意见》提出要率先落实数据产权和权益分配制度、加快推动数据资产价值实现、全面深化公共数据开发利用、培育发展数据要素市场、大力发展数据服务产业、开展数据基础制度先行先试、加强数据要素安全监管治理。其中在数据资产化领域，着重强调了探索建立结构性分置的数据产权制度，完善数据收益合理化分配，开展数据资产登记，探索数据资产入表新模式和探索数据资产金融创新几个明确的工作目标。

      </p>
      <img  src="../../../assets/mobile_images/news_block8_03.png" alt="" />
      <p>
        在本次大会上，北京国际大数据交易所颁发了北数所数据资产登记中心首批数据资产登记证书，涵盖了在电力、航天等多个领域。其中，传世科技的《全景流通数字地图》荣获了首批登记的数据资产中唯一一个代表医药流通行业的数据资产登记凭证。
      </p>
      <img  src="../../../assets/mobile_images/news_block8_04.png" alt="" />
      <p>
        传世科技的《全景流通数字地图》，是基于公司积累多年的国内领先的数字化能力，开发的数据产品，包括了终端服务能力象限数据、市场画像聚合数据和商品流通画像数据等。《全景流通数字地图》经过北京国际大数据交易所标准化专业核验评估流程，成功获得数据资产登记凭证，是北京国际大数据交易所对传世的数据体系和质量的肯定，也是传世在数据服务业务领域的重要开拓。传世科技将依托自身的数字化能力、向更多医药同行与客户无保留地分享和赋能，为推动行业的数据资产化发展尽微薄之力。
      </p>
      <p style="margin-top: 20px; background-color:rgb(255, 192, 0) ;text-align: center;text-indent:0">
        北京国际大数据交易所简介
      </p>
      <p>
        为贯彻落实近年来国家鼓励数据资源流通的发展战略，北京市于2020年服贸会期间，发布了《北京国际大数据交易所设立工作实施方案》，提出“探索建设北京国际大数据交易所，引导数据要素向先进生产力集聚，助力本市经济实现高质量发展”。
      </p>
      <p>
        按照市委市政府工作部署，2021年3月30日，由北京金融控股集团有限公司发起，注册成立了“北京国际大数据交易有限公司”，作为北京国际大数据交易所的运营主体，注册资本金2亿元。
      </p>
      <p>
        北京国际大数据交易所是北京市落实建设“国家服务业扩大开放综合示范区”和“中国（北京）自由贸易试验区”数字经济领域的重点项目，是北京市创建“全球数字经济标杆城市”的六大标杆工程之一，是北京市在数字经济时代战略布局的新型基础设施，是推动数据要素市场化配置的重大探索。
      </p>
      <p>
        北京国际大数据交易所将着眼于数据要素赋能产业升级，着力于破解数据交易痛点问题，依托“两区”优势资源，培育要素市场，探索建立集数据登记、评估、共享、交易、应用、服务于一体的数据流通机制，推动建立数据资源产权、交易流通、跨境传输和安全保护等基础制度和标准规范，引导数据资源要素汇聚和融合利用，强化数据交易中介服务能力的建设和安全合规体系的建设，建设数据安全流通机制，推动数据交易业务的监督管理和合规流通，探索构建新技术、新模式、新规则、新风控、新生态等五新数据交易服务体系，促进数据资源要素规范化整合、合理化配置、市场化交易、长效化发展，打造成为国内领先的数据交易基础设施和国际重要的数据跨境流动枢纽。
      </p>
      <p style=" text-align: center;font-size:20px">
        市 场 部 出 品 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <img  src="../../../assets/mobile_images/news_block7_end.png" alt="" />
      <p>
        深圳传世般若科技有限公司（以下简称“传世科技”）成立于2015年，国家高新技术企业，国内领先的医药流通数字化解决方案服务商。
      </p>

      <p>
        传世科技目前在深圳、北京、武汉、威海、成都、天津、徐州均成立子公司，为公司集团化发展奠定了坚实的基础。传世科技以智能硬件为基础，结合供应链SaaS、大数据及算法的人工智能物联网（AIoT），为药企和医院提供流通领域的数字化解决方案；通过人工智能物联网连接和调度医药流通资源为药企和医院提供数字化营销与供应链优化服务。
      </p>
      <img  src="../../../assets/mobile_images/news_block7_list.png" alt="" />
      <img  src="../../../assets/mobile_images/news_block7_eq.png" alt="" />
      
      
    </div>
    <div class="news-btn-box">
      <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
      <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
    </div>
    </div>
    <div class="mo-main-contain" v-show="$route.query.id == 9">
    <!-- 新闻九 -->
    <div class="news-title-box mb30">
      <div class="news-title">医药企业数智化峰会暨时空智友DHERP新产品发布会召开，传世科技发布瀑布流智能拣选设备</div>
      <div class="news-date">发布时间：2023-07-14 18:03</div>
    </div>
    <div class="news-content-box">
      <img  src="../../../assets/images/news_block6_top.gif" alt="" />
      <p>
        7月14日，由北京时空智友科技有限公司和济南时空超越科技有限公司主办的《医药企业数智化峰会暨时空智友DHERP新产品发布会》在济南正式召开。峰会围绕“数智医药时代，时空引领未来”为主题，邀请众多行业大咖做主题分享。峰会现场，时空医药企业数智化全新一代产品DHERP盛大发布。
      </p>
      <img  src="../../../assets/mobile_images/news_block9_01.png" alt="" />
      <p>
        时空软件深耕医药行业信息化30年，已为全国超过6000家企业提供服务。在依托深厚行业积淀，技术持续迭代升级后，此次全新一代转型产品DHERP发布，将加速帮助更多企业顺利实现数智化转型。
      </p>
      <img  src="../../../assets/mobile_images/news_block9_02.png" alt="" />
      <img  src="../../../assets/mobile_images/news_block9_03.png" alt="" />
      <p>
        传世科技受邀参加本次峰会，公司董事王伟先生发表《黑灯拣选引领智能仓库变革》的主题分享，向现场嘉宾和媒体朋友隆重介绍传世科技在拆零拣选领域取得的技术突破和产品优势。王伟先生表示，传世科技在拆零拣选领域深耕多年，采用的第六代拆零拣选技术的瀑布流智能拣选设备将热忱服务广大客户，提高客户在仓储、拣选和配送环节的工作效率，帮助客户实现降本增效。

      </p>
      <p style="margin-top: 20px; background-color:rgb(255, 192, 0) ;text-align: center;text-indent:0">
        传世科技瀑布流智能拣选设备功能介绍
      </p>
      <img  src="../../../assets/mobile_images/news_block9_04.png" alt="" />
      <img  src="../../../assets/mobile_images/news_block9_05.png" alt="" />
      <img  src="../../../assets/mobile_images/news_block9_06.png" alt="" />
      <img  src="../../../assets/mobile_images/news_block9_07.png" alt="" />
      
      
      
      
      <p style=" text-align: center;font-size:20px">
        市 场 部 出 品 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <img  src="../../../assets/mobile_images/news_block7_end.png" alt="" />
      <p>
        深圳传世般若科技有限公司（以下简称“传世科技”）成立于2015年，国家高新技术企业，国内领先的医药流通数字化解决方案服务商。
      </p>

      <p>
        传世科技目前在深圳、北京、武汉、威海、成都、天津、徐州均成立子公司，为公司集团化发展奠定了坚实的基础。传世科技以智能硬件为基础，结合供应链SaaS、大数据及算法的人工智能物联网（AIoT），为药企和医院提供流通领域的数字化解决方案；通过人工智能物联网连接和调度医药流通资源为药企和医院提供数字化营销与供应链优化服务。
      </p>
      <img  src="../../../assets/mobile_images/news_block7_list.png" alt="" />
      <img  src="../../../assets/mobile_images/news_block7_eq.png" alt="" />
      
      
    </div>
    <div class="news-btn-box">
      <div class="news-btn-prev news-btn" @click="toNext(0)">上一篇</div>
      <div class="news-btn-prev news-btn" @click="toNext(1)">下一篇</div>
    </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Box from '@/components/box/box.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
import MobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  name: 'home',
  components: {
    Box,
    Title,
    MobileImg
  },
  data() {
    return {
      rankImgUrl:require('../../../assets/mobile_images/news_block4_01.png')
    };
  },
  methods: {
    toNext(type){
      let id = Number(this.$route.query.id)
      if(type == 0 && id > 0){
        this.$router.push({
          path:'/news/detail',
          query:{
            id:(id - 1)
          }
        })
      }else if(type == 1 && id < 9){
        this.$router.push({
          path:'/news/detail',
          query:{
            id:(id + 1)
          }
        })
      }
        
    }
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  //头部
  .mobile-home-header {
    position: relative;
    min-height: 2.6rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  //中间
  .mo-main-contain {
    padding: 0 .4rem;
    box-sizing: border-box;
    .news-title-box {
      text-align: left;
      margin-top: .32rem;
      .news-title {
        font-size: .4rem;
        color: #3c3c3c;
      }
      .news-date {
        font-size: .24rem;
        color: #808080;
        margin-top: .2rem;
      }
    }
    .news-content-box {
      text-align: left;
      /deep/img {
        width: 100%;
        margin: .38rem 0;
      }
      /deep/p {
        font-size: .28rem;
        color: #3d3d3d;
        line-height: .5rem;
        text-indent: 2em;
      }
      h1{
        font-size: .36rem;
        text-align: center;
        margin: .6rem 0 .4rem 0;
      }
      h2{
        font-size: .32rem;
        line-height: .5rem;
        text-align: left;
      }
    }
    .news-btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.08rem 0;
      .news-btn {
        width: 2.04rem;
        height: .72rem;
        line-height: .72rem;
        text-align: center;
        background: #e0e5ed;
        color: #3D3D3D;
        font-size: .24rem;
        cursor: pointer;
        &:first-child{
            margin-right: 46px;
        }
      }
    }
  }
}
</style>
